<template>
  <NavBar :cardList="dataList" />
  <div>
    <section class="user-dashboard">
      <div class="container-lg">
        <div class="row g-3 g-xl-4 tab-wrap">
          <div class="col-lg-4 col-xl-3 sticky">
            <Sidebar />
          </div>

          <div class="col-lg-8 col-xl-9">
            <router-view :key="$route.fullPath" />
          </div>
        </div>
      </div>
    </section>
  </div>
  <Footer />
</template>

<script>
import { apiProductCardList } from "@/utils/apiCall";

import NavBar from "@/components/public/NavBar.vue";
import Footer from "@/components/public/Footer.vue";
import Sidebar from "@/components/user/Sidebar.vue";

export default {
  components: { NavBar, Footer, Sidebar },
  data() {
    return {
      userAgentDetails: {},
      dataList: [],
    };
  },

  methods: {
    apiProductCardList,
    sleep: function (time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
    handleUserAgentDetails() {
      this.userAgentDetails = this.$root.getUserAgentDetails();
    },
  },

  created() {
    window.addEventListener("resize", this.handleUserAgentDetails);
    this.handleUserAgentDetails();
  },

  destroyed() {
    window.removeEventListener("resize", this.handleUserAgentDetails);
  },

  async mounted() {
    this.userAgentDetails = this.$root.getUserAgentDetails();
    this.checkMounted = true;

    const d = await this.apiProductCardList();
    if (d) {
      this.dataList = d.data;
    }
  },
};
</script>

<style lang="scss" scoped></style>
