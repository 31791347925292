import { createApp } from 'vue';
import axios from 'axios';
import App from './App.vue';
import router from './router';
import store from './store';

import 'vazirmatn/Vazirmatn-font-face.css';
import 'vazirmatn/misc/Farsi-Digits/Vazirmatn-FD-font-face.css';

import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

// +++++++ multilanguage     +++++++ +++++++ +++++++ +++++++ +++++++ +++++++ +++++++ //
import i18n from './i18n';
// ####### END multilanguage ####### ####### ####### ####### ####### ####### ####### //

// +++++++ Essential Components     +++++++ +++++++ +++++++ +++++++ +++++++ +++++++ +++++++ //
import PrRouterView from '@/components/custom/PrRouterView.vue';
import PrDebugConsole from '@/components/custom/PrDebugConsole.vue';
import PrPreLoaderFull from '@/components/custom/PrPreLoaderFull.vue';
import PrLoginModal from '@/components/custom/PrLoginModal.vue';
import PrInputLabelAnimation from '@/components/custom/PrInputLabelAnimation.vue';

// ####### END Essential Components ####### ####### ####### ####### ####### ####### ####### //

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

createApp(App)
  .use(i18n)
  .use(Toast, {
    transition: 'Vue-Toastification__fade',
    maxToasts: 20,
    newestOnTop: true,
  })
  .use(store)
  .component('PrRouterView', PrRouterView)
  .component('PrDebugConsole', PrDebugConsole)
  .component('PrPreLoaderFull', PrPreLoaderFull)
  .component('PrLoginModal', PrLoginModal)
  .component('PrInputLabelAnimation', PrInputLabelAnimation)

  .use(router)
  .mount('#app');
