<template>
  <div>
    <div class="f w-100">
      <div class="f-i">
        <input
          :type="inputType"
          id="c_id_1"
          autocomplete="off"
          v-model="componentValue"
          required
        />
        <label for="c_id_1">{{ label }}</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },

    direction: {
      type: String,
      default: "",
    },

    textAlign: {
      type: String,
      default: "",
    },

    inputMaxlength: {
      type: Number,
      default: "10000",
    },

    inputType: {
      type: String,
      default: "number",
    },
  },
  emits: ["update:modelValue"],
  computed: {
    componentValue: {
      get() {
        return this.modelValue;
      },
      set(componentValue) {
        this.$emit("update:modelValue", componentValue);
      },
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="css" scoped>
*,
*:focus {
  outline: none;
}

.f {
  width: 500px;
  margin: 0 auto;
  font-family: Vazirmatn;
  background: transparent;
  direction: rtl;
  text-align: right;
  float: right;
  justify-content: right;
}
.f-i {
  position: relative;
  margin-bottom: 15px;
}
.f-i input {
  display: block;
  width: 100%;
  font-family: Vazirmatn;
  font-weight: normal;
  height: 40px;
  background: #142b26;
  border: solid 1px #cea675;
  border-radius: 7px;
  transition: all 0.3s ease;
  padding: 0 15px;
  color: #fff;
  text-align: left;
  direction: ltr;
}
.f-i input:focus {
  border-color: #cea675;
}
.f-i label {
  width: 98%;
  position: absolute;
  text-align: right;
  cursor: text;
  z-index: 2;
  top: 7px;
  left: 10px;
  font-size: 12px;
  font-weight: normal;
  background: transparent;
  padding: 0 10px;
  color: #fff;
  transition: all 0.3s ease;
}
.f-i input:focus + label,
.f-i input:valid + label {
  font-size: 11px;
  top: -21px;
}
.f-i input:focus + label {
  color: var(--bs-light);
}
</style>
