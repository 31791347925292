<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade"
      style="backdrop-filter: blur(14px)"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content" style="background-color: #183a33">
          <div
            class="modal-header"
            style="background-color: #183a33; border-radius: 14px"
          >
            <h3
              class="modal-title"
              id="exampleModalLabel"
              style="color: #cea675; text-align: center; width: 100%"
            >
              لطفا ابتدا به حساب کاربری خود وارد شوید
            </h3>
            <button
              type="button"
              class="close"
              style="background-color: #183a33"
              data-dismiss="modal"
              aria-label="Close"
              @click="modalClose()"
            ></button>
          </div>
          <div class="modal-body" style="background-color: #183a33">
            <div class="row d-flex justify-content-center mb-4 pb-4">
              <div class="col-lg-7 col-12">
                <div class="row">
                  <div
                    class="col-12"
                    style="border: 2px solid #cea675; border-radius: 20px"
                  >
                    <div
                      class="row mt-4"
                      style="text-align: center; justify-content: center"
                    >
                      <div class="col-12 my-2">
                        <img
                          src="/assets/images/logo_2.svg"
                          class="img-fluid"
                          style="height: 2rem"
                          alt=""
                        />
                      </div>
                      <div>
                        <h5 class="text-white">ورود | ثبت نام</h5>
                      </div>
                      <div class="col-12 mb-4">
                        <div
                          class="spinner-border text-light"
                          role="status"
                          v-show="showLoading"
                        >
                          <span class="sr-only"></span>
                        </div>
                      </div>
                      <div class="col-lg-12 my-2">
                        <div
                          class="row p-2 my-2"
                          v-if="showOtpSend"
                          style="border-radius: 7px"
                        >
                          <div class="col-12 my-3">
                            <div class="form-group">
                              <PrInputLabelAnimation
                                v-model="dataItem.mobile"
                                label="شماره موبایل"
                                :inputMaxlength="11"
                                inputType="text"
                              />
                            </div>
                          </div>
                          <div class="col-12"></div>
                          <div class="col-12">
                            <button
                              type="submit"
                              class="btn btn-warning w-100"
                              style="
                                background-color: #cea675;
                                border-color: #cea675;
                                color: var(--bs-dark);
                              "
                              @click="otpSend()"
                            >
                              ادامه
                            </button>
                          </div>
                        </div>

                        <div
                          class="row p-2 my-2"
                          v-if="showOtpVerify"
                          style="border-radius: 7px"
                        >
                          <div class="col-12 mt-2 mb-2">
                            <div class="form-group">
                              <PrInputLabelAnimation
                                v-model="dataItem.code"
                                label="کد ارسالی"
                                inputType="number"
                              />
                            </div>
                          </div>
                          <div class="col-12">
                            <button
                              type="submit"
                              class="btn btn-warning w-100"
                              style="
                                background-color: #cea675;
                                border-color: #cea675;
                                color: var(--bs-dark);
                              "
                              @click="otpVerify()"
                            >
                              تایید
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
//import $ from "jquery";
import axios from "axios";
import { toastSuccess, toastError } from "@/utils/toast";

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showOtpSend: true,
      showOtpVerify: false,
      showLoading: false,
      dataItem: {
        mobile: "",
        code: "",
      },
    };
  },

  watch: {
    show: function (newVal, oldVal) {
      if (newVal === true) {
        this.modalOpen();
      } else {
        this.modalClose();
      }
    },
  },
  methods: {
    modalOpen() {
      $("#exampleModal").modal("show");
    },
    modalClose() {
      $("#exampleModal").modal("hide");
    },

    async otpSend() {
      // this.v$.$validate();

      //  if (!this.v$.$error && !this.showLoading) {
      if (!this.showLoading) {
        this.showLoading = true;
        this.showOtpSend = false;
        // const isFormCorrect = await this.v$.$validate();
        const isFormCorrect = true;

        const data = JSON.stringify(this.dataItem);
        const url = "/v2/users/auth/otp/send";
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        this.showLoading = true;

        axios
          .post(url, data, config)
          .then((response) => {
            this.showOtpVerify = true;
            this.showLoading = false;
            setInterval(this.myTimer(), 1000);
          })
          .catch((error) => {
            this.showLoading = false;
            //  toastError("مشکلی پیش آمده ورودی هارو چک کنید");
          });
      }
    },

    async otpVerify() {
      // this.v$.$validate();

      //  if (!this.v$.$error && !this.showLoading) {
      if (!this.showLoading) {
        this.showLoading = true;
        this.showOtpVerify = false;
        // const isFormCorrect = await this.v$.$validate();
        const isFormCorrect = true;

        const data = JSON.stringify(this.dataItem);
        const url = "/v2/users/auth/otp/verify";
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        this.showLoading = true;

        axios
          .post(url, data, config)
          .then((response) => {
            this.showOtpVerify = true;
            const serverResponse = response.data;
            const token = serverResponse.data.accessToken;
            this.$store.commit("setUserToken", token);
            this.modalClose();
            toastSuccess("ورود موفقیت آمیز");
            //this.$router.push({ name: "PanelUser-Dashboard" });
            //location.reload();

            // if (this.$route.query.redirect) {
            //    this.$router.push(this.$route.query.redirect);
            //  } else {
            //    this.$router.push({ name: "PanelUser-Dashboard" });
            //  }
          })
          .catch((error) => {
            this.showLoading = false;
            toastError("مشکلی پیش آمده ورودی هارو چک کنید");
            // setTimeout(location.reload(), 2000);
          });
      }
    },
  },

  mounted() {},
};
</script>
  <style lang="css" scoped>
*,
*:focus {
  outline: none;
}

.f {
  width: 500px;
  margin: 0 auto;
  font-family: Vazirmatn;
  background: transparent;
  direction: rtl;
  text-align: right;
  float: right;
  justify-content: right;
}
.f-i {
  position: relative;
  margin-bottom: 15px;
}
.f-i input {
  display: block;
  width: 100%;
  font-family: Vazirmatn;
  font-weight: normal;
  height: 40px;
  background: #142b26;
  border: solid 1px #cea675;
  border-radius: 7px;
  transition: all 0.3s ease;
  padding: 0 15px;
  color: #fff;
  text-align: left;
  direction: ltr;
}
.f-i input:focus {
  border-color: #cea675;
}
.f-i label {
  width: 98%;
  position: absolute;
  text-align: right;
  cursor: text;
  z-index: 2;
  top: 7px;
  left: 10px;
  font-size: 12px;
  font-weight: normal;
  background: transparent;
  padding: 0 10px;
  color: #fff;
  transition: all 0.3s ease;
}
.f-i input:focus + label,
.f-i input:valid + label {
  font-size: 11px;
  top: -21px;
}
.f-i input:focus + label {
  color: var(--bs-light);
}
</style>
  