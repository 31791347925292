import store from '@/store';
import layout from '@/layouts/User';

import { routes as authRoutes } from './auth/Routes';
import { routes as routesAddresses } from './addresses/Routes';

/* نام گروه کابری برای مدیریت داشبرد یا پنل مورد نظر */
const groupName = store.state.userRoutesGroupName;
/* مسیری ریشه‌ای که برای گروه کاربری در نظر گرفته‌ایم */
const pathRoot = store.state.userRoutesPathRoot;
/* نام اصلی برای زیر مسیر‌های مدیریت داشبرد یا پنل مورد نظر */
const parentName = store.state.userRoutesParentName;

// ------- Routes -------- -------- -------- -------- -------- -------- -------- //
export const routes = [
  {
    name: parentName,
    path: pathRoot,
    component: layout,
    meta: {
      requiresAuth: true,
      group: groupName,
    },
    children: [
      ...routesAddresses(parentName),
      //...routesQuantumRandomNumbers(parentName),
      //...monitoringRoutes(parentName),

      {
        path: '',
        redirect: {
          name: parentName + '-' + 'Dashboard',
        },
      },

      {
        path: 'dashboard',
        name: parentName + '-' + 'Dashboard',
        component: () => import('./Dashboard.vue'),
      },

      {
        path: 'card',
        name: parentName + '-' + 'Card',
        component: () => import('./Card.vue'),
      },

      {
        path: 'order',
        name: parentName + '-' + 'Order',
        component: () => import('./Order.vue'),
      },

      {
        path: 'favorites',
        name: parentName + '-' + 'Favorites',
        component: () => import('./Favorites.vue'),
      },
      {
        path: 'wallet',
        name: parentName + '-' + 'Wallet',
        component: () => import('./Wallet.vue'),
      },

      {
        path: 'exchange',
        name: parentName + '-' + 'Exchange',
        component: () => import('./Exchange.vue'),
      },

      {
        path: 'profile',
        name: parentName + '-' + 'Profile',
        component: () => import('./Profile.vue'),
      },
    ],
  },

  // ####### Auth ####### ####### ####### ####### ####### ####### ####### ####### //
  /**
   * مهم:
   * چون لاگین و رجیستر از لایه پیشفرض گروه مدیریت پیروی نمی‌کنند باید خارج از فضای چیلدرن نوشته شود
   */

  ...authRoutes(parentName, pathRoot, groupName),
  // ####### END Auth ####### ####### ####### ####### ####### ####### ####### ####### //
];

// ------- END Routes -------- -------- -------- -------- -------- -------- -------- //

export const middleware = (record, next, checkAuth) => {
  // ------- Check Group ------- ------- ------- ------- ------- ------- ------- //
  if (record.meta.group === groupName) {
    // ------- Check Auth ------- ------- ------- ------- ------- ------- ------- //
    if (record.meta.requiresAuth) {
      //const redirectPath = window.location.pathname; // برگشت به آدرس این صفحه
      if (checkAuth) {
        next();
      } else {
        next({
          name: `${parentName}-Login`,
          query: { redirect: window.location.pathname },
        });
      }
    }
    // ####### END Check Auth ####### ####### ####### ####### ####### ####### ####### //

    // ------- Check Auth Reverse ------- ------- ------- ------- ------- ------- ------- //
    else if (record.meta.requiresAuthReverse) {
      if (!checkAuth) {
        next();
      } else {
        next({
          name: `${parentName}-Login`,
        });
      }
    }
    // ####### END Check Auth  Reverse ####### ####### ####### ####### ####### ####### #######  //
  }
  // ####### END Check Group ####### ####### ####### ####### ####### ####### ####### //
};
