import axios from 'axios';
import jmoment from 'jalali-moment';
import { toastSuccess, toastError } from '@/utils/toast';

export const apiUserGetSelf = async () => {
  const url = '/v2/users';

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const axiosResponse = await axios.get(url, config);
    let serverResponse = axiosResponse.data;
    return serverResponse;
  } catch (e) {
    return false;
  }
};

export const apiProductGetByid = async (id = '') => {
  const url = '/public/products/' + id;

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const axiosResponse = await axios.get(url, config);
    let serverResponse = axiosResponse.data;
    return serverResponse;
  } catch (e) {
    return false;
  }
};

export const apiProductCardAdd = async (id = '', color = null, size = null, number = null) => {
  const url = '/v2/card/';
  const data = {
    productId: id,
    color: color,
    size: size,
    number: number,
  };
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (color === null || size === null) {
    toastError('ddfdfdf');
    return { status: 'error', message: 'لطفا رنگ و سایز را انتخاب کنید' };
  } else {
    try {
      const axiosResponse = await axios.post(url, data, config);
      let serverResponse = axiosResponse.data;
      return serverResponse;
    } catch (e) {
      return { status: 'error', message: e.message };
    }
  }
};

export const apiProductCardList = async () => {
  const url = '/v2/card/';

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const axiosResponse = await axios.get(url, config);
    let serverResponse = axiosResponse.data;
    return serverResponse;
  } catch (e) {
    return false;
  }
};

export const apiOrderCreate = async () => {
  const url = '/v2/orders/';
  const data = {};
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const axiosResponse = await axios.post(url, data, config);
    let serverResponse = axiosResponse.data;
    return serverResponse;
  } catch (e) {
    return false;
  }
};

export const apiOrderFindManySelf = async () => {
  const url = '/v2/orders/';
  // const data = {};
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const axiosResponse = await axios.get(url, config);
    let serverResponse = axiosResponse.data;
    return serverResponse;
  } catch (e) {
    return false;
  }
};
