<template>
  <!-- Document Footer Start -->
  <footer
    class="footer-document footer-document2 ratio_asos mb-xxl"
    style="background-image: url('/assets/png/about-pattern.png') !important"
  >
    <!-- <div class="bg-footer-l">
      <img src="/assets/images/bag/banner/bg-footer-l.png" alt="banner" />
    </div> -->
    <!-- <div class="bg-footer-r">
      <img src="/assets/images/bag/banner/bg-footer-r.png" alt="banner" />
    </div> -->
    <div>
      <div class="container-lg">
        <div class="main-footer">
          <div class="row gy-3 gy-md-4 gy-xl-0">
            <div class="col-xl-3 col-lg-4 col-md-6">
              <div class="content-box">
                <img
                  class="logo"
                  src="/assets/images/logo_2.svg"
                  alt="logo-white"
                />
                <ul>
                  <li>
                    <i data-feather="map-pin"></i>
                    <span> ایران ، تبریز </span>
                  </li>
                  <li>
                    <i data-feather="phone"></i
                    ><a class="nav hover-footer-element" href="tel:185659635"
                      ><span dir="ltr" class="hover-footer-element">
                        +989002700232
                      </span></a
                    >
                  </li>
                  <li>
                    <i data-feather="mail"></i
                    ><a
                      class="hover-footer-element nav"
                      href="mailto:fashion098@gmail.com"
                      ><span> info@varenistyle.com </span></a
                    >
                  </li>
                </ul>
              </div>
            </div>

            <div class="nav-footer col-lg-2 col-md-4 order-md-3 order-lg-2">
              <div class="nav content-box d-md-block">
                <h5 class="heading-footer line-style">خرید توسط</h5>
                <ul>
                  <li>
                    <a class="nav" href="shop-left-sidebar.html">جدید در </a>
                  </li>
                  <li>
                    <a class="nav" href="shop-left-sidebar.html">زنان </a>
                  </li>
                  <li>
                    <a class="nav" href="shop-left-sidebar.html">مردان </a>
                  </li>
                  <li>
                    <a class="nav" href="shop-left-sidebar.html">کفش</a>
                  </li>
                  <li>
                    <a class="nav" href="shop-left-sidebar.html"
                      >کیف و لوازم جانبی
                    </a>
                  </li>
                  <li>
                    <a class="nav" href="shop-left-sidebar.html"
                      >برندهای برتر
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div
              class="nav-footer col-xl-2 col-lg-3 col-md-4 order-md-4 order-lg-3"
            >
              <div class="nav d-md-block content-box">
                <h5 class="heading-footer line-style">اطلاعات</h5>
                <ul>
                  <li><a class="nav" href="/">صفحه اصلی </a></li>
                  <li>
                    <a class="nav" href="shop-left-sidebar.html">خرید </a>
                  </li>
                  <li><a class="nav" href="about-us.html">درباره ما</a></li>
                  <li><a class="nav" href="blog-detail.html">وبلاگ </a></li>
                  <li><a class="nav" href="contact-us.html">تماس با </a></li>
                </ul>
              </div>
            </div>

            <div
              class="nav-footer col-xl-2 col-lg-3 col-md-4 order-md-5 order-lg-4"
            >
              <div class="nav d-md-block content-box">
                <h5 class="heading-footer line-style">راهنما دریافت کنید</h5>
                <ul>
                  <li>
                    <a class="nav" href="user-dashboard.html">سفارشات شما </a>
                  </li>
                  <li>
                    <a class="nav" href="user-dashboard.html">حساب شما </a>
                  </li>
                  <li>
                    <a class="nav" href="user-dashboard.html">ردیابی سفارش</a>
                  </li>
                  <li>
                    <a class="nav" href="wishlist.html">لیست خواسته های شما</a>
                  </li>
                  <li><a class="nav" href="search.html">جستجو</a></li>
                  <li><a class="nav" href="faqs.html">پرسش‌ها</a></li>
                </ul>
              </div>
            </div>

            <div class="col-xl-3 col-md-6 col-lg-4 order-md-2 order-lg-5">
              <div class="content-box">
                <h5 class="heading-footer line-style">ما را دنبال کنید</h5>
                <div class="follow-wrap">
                  <ul>
                    <li>
                      <a href="https://www.instagram.com/varenistyle/">
                        <img src="/assets/icons/svg/social/fb.svg" alt="fb" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/varenistyle/">
                        <img src="/assets/icons/svg/social/inta.svg" alt="fb" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/varenistyle/">
                        <img src="/assets/icons/svg/social/tw.svg" alt="fb" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/varenistyle/">
                        <img src="/assets/icons/svg/social/pint.svg" alt="fb" />
                      </a>
                    </li>
                  </ul>
                </div>

                <div class="subscribe-box">
                  <h5 style="color: #cea675 !important">ثبت نام در خبرنامه</h5>
                  <p>
                    آخرین به‌روزرسانی‌های ما را درباره محصولات و تبلیغات ما
                    دریافت کنید.
                  </p>
                </div>

                <form action="javascript:void(0)" class="footer-form">
                  <input
                    required
                    type="email"
                    class="form-control"
                    placeholder="آدرس ایمیل شما"
                  />

                  <button
                    type="submit"
                    style="
                      background-color: #cea675 !important;
                      color: #183a33 !important;
                    "
                    class="btn btn-solid round-corner line-none btn-solid2"
                  >
                    ارسال
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- Document Footer End -->
</template>

<script>
import PrTextLogo from "@/components/custom/PrTextLogo.vue";

export default {
  components: { PrTextLogo },
};
</script>

<style scoped>
.footer-document {
  background: #183a33 !important;
}

.footer-document .main-footer .content-box ul li .nav:hover {
  color: #cea675 !important;
}

.line-style::before {
  background-color: #cea675 !important;
}

.hover-footer-element:hover {
  color: #cea675 !important;
}
</style>
