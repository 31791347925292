<template>
  <NavBar :cardList="dataList" />
  <main class="main"><router-view :key="$route.fullPath" /></main>
  <Footer />
</template>

<script>
import axios from "axios";
import NavBar from "@/components/public/NavBar.vue";
import Footer from "@/components/public/Footer.vue";
import { checkAuth, checkAuthOperator, checkAuthUser } from "@/utils/checkAuth";
import { apiProductCardList } from "@/utils/apiCall";

export default {
  components: { NavBar, Footer },
  data() {
    return { dataList: [] };
  },
  methods: {
    apiProductCardList,
    sleep: function (time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
    async checkAreaAccess() {
      this.$store.commit("readUserToken");
      if (this.$store.state.userToken) {
        const authResponse = await checkAuthUser(
          "/v2/users/auth/validate",
          this.$store.state.userToken
        );

        this.sleep(100).then(async () => {
          if (authResponse.status) {
            this.$store.state.userDetails = authResponse.data;
            this.$store.commit("setUserToken", this.$store.state.userToken);

            //    this.getData();

            const d = await this.apiProductCardList();
            if (d) {
              this.dataList = d.data;
            }
          } else {
            this.$store.commit("clearUserToken");
          }
        });
      } else {
        this.$store.commit("clearUserToken");
      }
    },
  },
  async created() {
    await this.checkAreaAccess();
  },
  mounted() {
    this.sleep(2000).then(() => {});
  },
};
</script>

<style>
#shadow-host-companion {
  padding: 0 !important;
}
</style>
