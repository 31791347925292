<template>
  <router-view :key="$route.fullPath" />
</template>
<script>
import axios from "axios";

import { checkAuth, checkAuthOperator, checkAuthUser } from "@/utils/checkAuth";
import setLanguage from "@/utils/language";

export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
  methods: {
    sleep: function (time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },

    getUserAgentDetails() {
      let screenHeight = window.innerHeight;
      let screenWidth = window.innerWidth;

      let userAgentDetails = {
        screenHeight: screenHeight,
        screenWidth: screenWidth,
        screenType: "",
        device: "",
        os: "",
        browser: "",
      };

      let min_number = 992;

      if (window.innerWidth < min_number) {
        userAgentDetails["screenType"] = "small";
      } else {
        userAgentDetails["screenType"] = "large";
      }

      let userAgent = navigator.userAgent;
      userAgentDetails["os"] = navigator.platform;

      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
        userAgentDetails["device"] = "tablet";
        userAgentDetails["screenType"] = "small";
        if (screenWidth > screenHeight && screenWidth > min_number) {
          userAgentDetails["screenType"] = "large";
        }
      } else if (
        /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
          userAgent
        )
      ) {
        userAgentDetails["device"] = "mobile";
        userAgentDetails["screenType"] = "small";
      }

      if (userAgent.split(",")[0] == "AndroidWebApk") {
        userAgentDetails["os"] = "Android";
        userAgentDetails["browser"] = userAgent.split(",")[0];
        userAgentDetails["manufacturer"] = userAgent.split(",")[1];
        userAgentDetails["model"] = userAgent.split(",")[2];
        userAgentDetails["version"] = userAgent.split(",")[3];
      } else if (userAgent.split(",")[0] == "IOSWebApp") {
        userAgentDetails["os"] = "IOS";
      }
      console.log(userAgentDetails);
      return userAgentDetails;
    },
  },

  async created() {
    this.$store.commit("readDashboardLanguage");
    if (this.$store.state.dashboardLanguage === "en") {
      setLanguage("en");
    } else {
      setLanguage("fa");
    }

    //  await this.checkAreaAccess();
  },
};
</script>

<style>
.carousel-caption {
  font-size: 12px;
  font-style: italic;
  font-weight: bold;
  /* display: none;  remove comment to hide captions */
}

.carousel-control {
  text-shadow: 0;
}

.carousel-control.left {
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.0001) 100%);
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 100%);
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0)), to(#000000));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 100%) !important;
}

.carousel-control.right {
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.0001) 100%);
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 100%);
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0)), to(#000000));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 100%) !important;
}


body {
  font-family: "Vazirmatn" !important;
}

div {
  font-family: "Vazirmatn" !important;
}

span {
  font-family: "Vazirmatn" !important;
}

a {
  font-family: "Vazirmatn" !important;
}

p {
  font-family: "Vazirmatn" !important;
}

label {
  font-family: "Vazirmatn" !important;
}

strong {
  font-family: "Vazirmatn" !important;
}

section {
  font-family: "Vazirmatn" !important;
}

main {
  font-family: "Vazirmatn" !important;
}

li {
  font-family: "Vazirmatn" !important;
}

ul {
  font-family: "Vazirmatn" !important;
}

ol {
  font-family: "Vazirmatn" !important;
}

input {
  font-family: "Vazirmatn" !important;
}

textarea {
  font-family: "Vazirmatn" !important;
}

select {
  font-family: "Vazirmatn" !important;
}

option {
  font-family: "Vazirmatn" !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Vazirmatn" !important;
  line-height: 1.6;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb {
  background-color: #183a33;
  border-radius: 100px;
}
</style>
