<template>
  <div>
    <div class="loader-wrapper">
      <div class="loader animate">
        <span>xxx</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
