<template>
  <button
    @click="toggleSideBar()"
    class="setting-menu btn-solid btn-sm d-lg-none"
  >
    منوی تنظیم <i class="arrow"></i>
  </button>
  <div id="sidebarr" class="side-bar bg-vareni-brown-gradient">
    <span @click="toggleSideBar()" class="back-side d-lg-none">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-x"
      >
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line></svg
    ></span>
    <div class="profile-box">
      <div class="img-box">
        <img class="img-fluid" src="/assets/png/profile.png" alt="user" />
        <div class="edit-btn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-edit"
          >
            <path
              d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
            ></path>
            <path
              d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
            ></path>
          </svg>
          <input class="updateimg" type="file" name="img" />
        </div>
      </div>

      <div class="user-name">
        <h5>{{ a }}</h5>
        <h6>mahdi@gmail.com</h6>
      </div>
    </div>

    <ul class="nav nav-tabs nav-tabs2" id="myTab" role="tablist">
      <li
        class="nav-item"
        role="presentation"
        v-for="(item, index) in dataList"
        :key="index + 'sidebar-route-list-dsfdsf'"
      >
        <router-link
          :to="{ name: item.pathName }"
          class="nav-link"
          style="
            color: var(--theme-color-green);
            font-weight: 200;
            font-size: 14px;
          "
          id="dashboard-tab"
          type="button"
          role="tab"
        >
          {{ item.text }}
          <span>
            <svg
              style="transform: rotateY(180deg)"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-chevron-right"
            >
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      a: "مهدی",
      dataList: [
        { text: "داشبورد", pathName: "PanelUser-Dashboard" },
        { text: "سفارشات", pathName: "PanelUser-Order" },
        { text: "لیست علاقه‌مندی‌ها", pathName: "PanelUser-Favorites" },
        { text: " آدرس ذخیره شده", pathName: "PanelUser-Addresses" },
        { text: "مشخصات", pathName: "PanelUser-Profile" },
      ],
    };
  },
  methods: {
    toggleSideBar() {
      let x = document.getElementById("sidebarr");
      x.classList.toggle("show-menu");
    },
  },
};
</script>

<style lang="css" scoped>
.sidebar-c {
}
.router-link-active.sidebar-item-active {
  color: var(--theme-color);
  background-color: #fff;
}
</style>
