<template>
    <div>
        <pre class="bg">
          {{ JSON.stringify(data, null, 4) }}
          </pre>
    </div>
</template>


<script>
export default {
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    methods: {
        sleep: function (time) {
            return new Promise((resolve) => setTimeout(resolve, time));
        },
    },
    mounted() { },
};
</script>


<style scoped>
.bg {
    font-size: 14px;
    background-color: #000;
    color: #00e700;
    overflow-x: hidden;
    overflow-y: auto;
    height: 490px;
    padding: 7px;
    border-radius: 7px;
    direction: ltr;
    text-align: left;
}
</style>