import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
import LayoutPublic from '../layouts/Public.vue';
import LayoutUser from '../layouts/User.vue';

import { routes as userRoutes, middleware as userMiddleware } from '@/views/user/Routes';

const routes = [
  {
    path: '/',
    name: 'Public',
    component: LayoutPublic,
    children: [
      {
        path: '',
        name: 'PublicHome',
        component: () => import('../views/public/Home'),
        //redirect: (to) => {
        //return '/user/';
        //},
      },
      {
        path: 'about-us',
        name: 'About',
        component: () => import('../views/public/About-us.vue'),
        //redirect: (to) => {
        //return '/user/';
        //},
      },
      {
        path: 'contact-us',
        name: 'Contact',
        component: () => import('../views/public/Contact-us.vue'),
        //redirect: (to) => {
        //return '/user/';
        //},
      },
      {
        path: 'products',
        name: 'Products',
        component: () => import('../views/public/Products.vue'),
        //redirect: (to) => {
        //return '/user/';
        //},
      },
      {
        path: 'products/:id',
        name: 'Product',
        //  query: route.id,
        component: () => import('../views/public/Product.vue'),
        //redirect: (to) => {
        //return '/user/';
        //},
      },

      {
        path: 'favorites',
        name: 'Favorites',
        component: () => import('../views/public/Favorites.vue'),
        //redirect: (to) => {
        //return '/user/';
        //},
      },

      {
        path: 'home2',
        name: 'PublicHome2',
        component: () => import('../views/public/Home2'),
        //redirect: (to) => {
        //return '/user/';
        //},
      },
      {
        path: 'verify',
        name: 'PublicVerify',
        component: () => import('../views/public/Verify'),
      },
    ],
  },

  ...userRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => {
      /**
       * خیلی مهم:
       * میدلور هر لایه حساب کاربری باید به طوری جداگانه فراخوانی شود
       * و در زیر قرار بگیرد
       *
       **/
      // console.log(record);

      userMiddleware(record, next, store.state.userIsAuthenticated);
    })
    /* eslint-disable */
  ) {
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
